<template>
    <div :is="layout">
        <div id="cesiumContainer"></div>
    </div>
</template>
<script>
    import { get_list } from "../../../api/airport_cesium";

    let Cesium;
    export default {
        name: 'airportCesium',
        data: () => ({
            layout: null,
        }),
        methods: {
            init() {
                const viewer = new Cesium.Viewer('cesiumContainer')
                get_list({ node_type: 2, airport_id: this.$route.params.id, pagesize: 9999 }).then(res => {
                    const air_node = res.results
                    const customDataSource = new Cesium.CustomDataSource('customDataSource')
                    air_node.forEach(x => {
                        const entities = new Cesium.Entity({
                            name: x.node_name,
                            position: Cesium.Cartesian3.fromDegrees( parseFloat(x.lng), parseFloat(x.lat)),
                            billboard: {
                                show: true,
                                image: 'https://slhz-1255710621.cos.ap-nanjing.myqcloud.com/aircraft.png',
                                scale: .1
                            }
                        })
                        customDataSource.entities.add(entities)
                    })
                    customDataSource.clustering.enabled = true
                    customDataSource.clustering.pixelRange = 80
                    customDataSource.clustering.minimumClusterSize = 10
                    customDataSource.clustering.clusterEvent.addEventListener((clusteredEntities, cluster) => {
                        cluster.label.show = true
                        cluster.label.text = clusteredEntities.length.toString()
                        cluster.label.font = '16px sans-serif'
                        cluster.label.style = Cesium.LabelStyle.FILL_AND_OUTLINE
                        cluster.label.fillColor = Cesium.Color.RED
                    })
                    viewer.dataSources.add(customDataSource)
                    viewer.flyTo(customDataSource)
                })
            },

        },
        mounted() {
            this.layout = this.$route.meta.use_layout
            Cesium = this.Cesium
            this.$nextTick(() => {
                this.init()
            })
        }
    }
</script>
<style lang="stylus" scoped>

</style>
